//TODO LINT
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import AccordionList from '../../../../../components/AccordionList'
import ToursTable from '.'

import VolumeIcon from '../../../../../assets/icons/cube.svg'
import EuroIcon from '../../../../../assets/icons/euro.svg'
import TruckIcon from '../../../../../assets/icons/polaris/TruckIcon'
import Horloge from '../../../../../assets/icons/polaris/horlog'
import PlaceIcon from '../../../../../assets/icons/place.svg'
import TrajectoireIcon from '../../../../../assets/icons/trajectoire'
import {
  convertirMinutesEnHeuresEtMinutes,
  getCreneauHoursMinutes,
} from '../../../../../utils/utils'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Switch from '../../../../../components/switch/SwitchComponent'
import Typography from '@mui/material/Typography'
import { StyledDivSwitch, StyledHeaderPlanning } from './style'
import PoidsLivraisonIcon from '../../../../../assets/icons/polaris/PoidsLivraison.svg'
import PoidsEnlevementIcon from '../../../../../assets/icons/polaris/PoidsEnlevement.svg'
import TruckNumberIcon from '../../../../../assets/icons/polaris/MdiTruckDeliveryOutline'
import ActionsTours from './actionTours'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { Button, Menu, MenuItem } from '@mui/material'
import FilterIcon from '../../../../../assets/icons/iconFilterTableCell'
import _ from 'lodash'

import PoidsEnlevement from '../../../../../assets/icons/polaris/PoidsEnlevement'
import PoidsLivraison from '../../../../../assets/icons/polaris/PoidsLivraison'
export default function ToursDetails({
  planning,
  setTabIndex,
  tabIndex,
  focusedTours,
  isRecalculateLoading,
  handleCheck,
  handleCheckAll,
  onChecked,
  checked,
  setOpenDeattributeDialog,
  isOptimisationCoursesFiles,
  verrouillerTournee,
  deVerrouillerTournee,
  inversionTournee,
  expand,
  onExpend,
  inversionOrderTournee,
  selectedAccodions,
  handleChangeAccordion,
  //menu filtre tournee
  filterTournee,
  planningFiltered,
  setSelectedFilterTournee,
  selectedFilterTournee,
  anchorEl,
  openFilter,
  handleClick,
  handleClose,
}) {
  const handleDisable = () => {
    return planningFiltered
      .map((i) => (i.markers.length === 2 ? false : true))
      .some((obj) => obj === true)
  }

  const onVerrouillerTournee = () => {
    verrouillerTournee(focusedTours)
  }

  const onDeVerrouillerTournee = () => {
    deVerrouillerTournee(focusedTours)
  }
  const posTournee = (tour) => {
    return planning.map((el) => el.driver.id).indexOf(tour.driver.id)
  }

  return (
    <>
      <StyledHeaderPlanning
        value={tabIndex}
        variant="scrollable"
        onChange={(e, y) => {
          setTabIndex(y)
          e.stopPropagation()
        }}
        sx={{
          position: 'sticky',
          top: 0,
          color: '#fff',
          zIndex: 10,
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          background: '#1976d2',
          height: '45px',
          padding: '0 10px',
          justifyContent: 'space-between',
        }}
      >
        <Button
          id="basic-button"
          aria-controls={openFilter ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilter ? 'true' : undefined}
          onClick={handleClick}
          sx={{ color: 'white', textTransform: 'none' }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {selectedFilterTournee?.title}
            <FilterIcon
              style={{
                marginLeft: '5px',
                width: '18px',
                height: '18px',
              }}
            />
          </div>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFilter}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {filterTournee.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  handleClose()
                  setSelectedFilterTournee(item)
                }}
                disabled={
                  (item.code == 'tourneesSelect' && focusedTours.length == 0) ||
                  (item.code == 'tourneesVide' &&
                    planning.every((el) => el.markers.length > 2))
                }
              >
                {item.label}
              </MenuItem>
            )
          })}
        </Menu>
        <StyledDivSwitch>
          {!isOptimisationCoursesFiles && (
            <FormGroup row spacing={1} alignItems="center">
              <Typography sx={{ alignItems: 'center' }}>Magasin</Typography>
              <FormControlLabel
                sx={{ margin: 0 }}
                control={
                  <Switch
                    value={checked}
                    defaultChecked={checked}
                    onChange={(e) => {
                      onChecked()
                      e.stopPropagation()
                    }}
                    id={'switch-MagEnt'}
                    disabled={handleDisable()}
                  />
                }
              />
              <Typography sx={{ alignItems: 'center' }}>Entrepot</Typography>
            </FormGroup>
          )}
          <>
            <ActionsTours
              isOptimisationCoursesFiles={isOptimisationCoursesFiles}
              planning={planningFiltered}
              planningOriginal={planning}
              focusedTours={focusedTours}
              setOpenDeattributeDialog={setOpenDeattributeDialog}
              onVerrouillerTournee={onVerrouillerTournee}
              onDeVerrouillerTournee={onDeVerrouillerTournee}
              inversionTournee={inversionTournee}
              inversionOrderTournee={inversionOrderTournee}
            />
            <FontAwesomeIcon
              style={{ cursor: 'pointer', marginLeft: '20px' }}
              icon={expand ? faCompressAlt : faExpandAlt}
              onClick={() => onExpend(!expand)}
            />
          </>
        </StyledDivSwitch>
      </StyledHeaderPlanning>
      <AccordionList
        list={planningFiltered.map((tour, i) => {
          return {
            color: tour.color,
            heading: tour.driver?.firstName,
            lock: tour.lock,
            summary: [
              {
                label: <TruckNumberIcon />,
                content: `${(tour.markers.length - 2) / 2} / ${tour.stopsNumber}`,
                unit: 'Missions / Stops',
                show: true,
              },
              {
                label: <img src={PlaceIcon} />,
                content: tour.distance,
                unit: 'Distance',
                show: true,
              },
              {
                label: <img src={EuroIcon} />,
                content: tour.montantPrestataireHT,
                unit: 'Prix',
                show: true,
              },
              {
                label: (
                  <PoidsEnlevement
                    color={!tour?.exceededPickupWeight ? '#373d57' : 'red'}
                  />
                ),
                content: tour.tourPickupWeight,
                unit: !tour?.exceededPickupWeight
                  ? 'Poids Livraison'
                  : `Dépassement de ${tour.exceededPickupWeight} kg`,
                show: true,
                color: !tour?.exceededPickupWeight ? '#373d57' : 'red',
              },
              {
                label: (
                  <PoidsLivraison
                    color={!tour?.exceededDropoffWeight ? '#373d57' : 'red'}
                  />
                ),
                content: tour.tourDropoffWeight,
                unit: !tour?.exceededDropoffWeight
                  ? 'Poids Reprises'
                  : `Dépassement de ${tour.exceededDropoffWeight} kg`,
                show: true,

                color: !tour?.exceededDropoffWeight ? '#373d57' : 'red',
              },

              {
                label: <img src={VolumeIcon} />,
                content: tour.totalVolume,
                unit: 'Volume',
                show: true,
              },
              {
                label: (
                  <Horloge color={!tour?.exceededAmplitude ? '#373d57' : 'red'} />
                ),
                content: tour.totalTime,
                unit: !tour?.exceededAmplitude
                  ? 'Temps de service'
                  : `Dépassement de ${convertirMinutesEnHeuresEtMinutes(
                      tour.exceededAmplitude
                    )}`,
                show: true,
                color: !tour?.exceededAmplitude ? '#373d57' : 'red',
              },
              {
                label: <TrajectoireIcon />,
                content: tour.trajetTime,
                unit: 'Temps conduite',
                show: true,
              },
              {
                label: <TruckIcon />,
                content: tour.driver?.vehiculeType,
                unit: 'Vehicule',
                show: true,
              },
            ],
            isFocused: focusedTours.includes(posTournee(tour)),
            expanded: selectedAccodions.includes(posTournee(tour)),

            index: posTournee(tour),
            path: tour.path,
            details: (
              <ToursTable
                tours={planningFiltered[i].markers
                  .filter((item) =>
                    isOptimisationCoursesFiles ? item.type != 'pickup' : item
                  )
                  .map((visit, j) => {
                    return {
                      rejectedKeys: visit.rejectedKeys,
                      checked: visit.checked,
                      order: isOptimisationCoursesFiles ? j : visit.order,
                      location_id: visit.location_id,
                      location_name: visit.location_name,
                      ldv:
                        visit.type == 'depotStart'
                          ? 'Début de la tournée'
                          : visit.type == 'depotEnd'
                          ? 'Fin de la tournée'
                          : visit.ldv,
                      arrival: visit.arrival_time,
                      finish: visit.finish_time,
                      type: visit.type,
                      day: visit.day,
                      courseRank: visit.courseRank,
                      too_late: visit.too_late,
                      weight: visit.weight,
                      volume: visit.volume,
                      montantPrestataireHT: visit.montantPrestaHT,
                      courseType: visit.courseType,
                      pickup:
                        visit.type == 'pickup'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff1:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.windowStart,
                              visit.windowEnd,
                              '~'
                            )
                          : '-',
                      dropoff2:
                        visit.type == 'dropoff'
                          ? getCreneauHoursMinutes(
                              visit.secondWindowStart,
                              visit.secondWindowEnd,
                              '~'
                            )
                          : '-',
                      courseCode: visit.location_name,
                      salesChanel: visit.salesChanel,
                      indexVisit: j,
                      receptionNumber: visit.location_id,
                      idleTime: visit.idleTime,
                      client: visit.client,
                      prestaTime: visit.prestaTime,
                      codePostale: visit.codePostale,
                      keys: visit.keys,
                      lateBy: visit.lateBy,
                      slotOverRun: visit.slotOverRun,
                      orderGiver: visit.orderGiver,
                    }
                  })}
                index={posTournee(tour)}
                isOpen={selectedAccodions.includes(posTournee(tour))}
                isRecalculateLoading={isRecalculateLoading}
                handleCheck={handleCheck}
                handleCheckAll={handleCheckAll}
                indexPlanning={posTournee(tour)}
                checkedAll={tour.checkedAll}
                nbVisitChecked={tour.nbVisitChecked}
                isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                lock={tour.lock}
              />
            ),
            handleChange: handleChangeAccordion,
          }
        })}
      />
    </>
  )
}
ToursDetails.propTypes = {
  focusedTours: PropTypes.array,
  isRecalculateLoading: PropTypes.bool,
  planning: PropTypes.any,
  handleCheck: PropTypes.func,
  handleCheckAll: PropTypes.func,
  onChecked: PropTypes.func,
  checked: PropTypes.any,
  disabled: PropTypes.bool,
  setSizeTable: PropTypes.func,
  setOpenDeattributeDialog: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  verrouillerTournee: PropTypes.func,
  deVerrouillerTournee: PropTypes.func,
  inversionTournee: PropTypes.func,
  inversionOrderTournee: PropTypes.func,
  selectedAccodions: PropTypes.array,
  handleChangeAccordion: PropTypes.func,
  filterTournee: PropTypes.array,
  planningFiltered: PropTypes.array,
  setSelectedFilterTournee: PropTypes.func,
  selectedFilterTournee: PropTypes.object,
  anchorEl: PropTypes.any,
  openFilter: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
}
